import React, { useState, useEffect, useRef } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { FiPhoneCall } from 'react-icons/fi';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Fade from 'react-reveal/Fade';

// import Swiper core and required modules
import HeroImage1 from "../assets/swiper-images/1.jpg"
import HeroImage2 from "../assets/swiper-images/2.jpg"
import HeroImage3 from "../assets/swiper-images/3.jpg"
import HeroImage1Mobilemd from "../assets/swiper-images/1-mobile-xs.jpg"
import HeroImage2Mobilemd from "../assets/swiper-images/2-mobile-xs.jpg"
import HeroImage3Mobilemd from "../assets/swiper-images/3-mobile-xs.jpg"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { HashLink as Link } from 'react-router-hash-link'



const Herosection = () => {
    const [isGreaterThenmd, setisGreaterThenmd] = useState(false);
    const [activeIndex, setactiveIndex] = useState(0);
    const swiperRef = useRef(null);

    const images = [{
        image: HeroImage2,
        objectPosition: "object-center",
        mobileImageXs: HeroImage2Mobilemd,
        title: "Welcome to Bcumin",
        subtitle: "Veterinary Excellence, Compassionate Care.",
        left: true
    }, {
        image: HeroImage3,
        objectPosition: "object-bottom",
        mobileImageXs: HeroImage1Mobilemd,
        title: "Innovation",
        subtitle: "We ensure brighter, healthier future to livestock industry.",
        left: true,
    }, {
        image: HeroImage1,
        objectPosition: "object-center",
        mobileImageXs: HeroImage3Mobilemd,
        title: "Quality Assurance",
        subtitle: "Ensuring Standards of Quality and Safety.",
        left: false
    }]

    useEffect(() => {
        const updateScreenSizeInfo = () => {
            if (window.innerWidth > 768) {
                setisGreaterThenmd(true);
            } else {
                setisGreaterThenmd(false);
            }

        };

        updateScreenSizeInfo();

        window.addEventListener('resize', updateScreenSizeInfo);

        return () => {
            window.removeEventListener('resize', updateScreenSizeInfo);
        };
    }, []);

    const updateActiveIndex = () => {
        setactiveIndex(swiperRef.current?.swiper?.realIndex)
    }

    return (
        <div id="top" className='w-full flex relative items-center'>
            <Swiper
                ref={swiperRef}
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                draggable={true}
                onSlideChangeTransitionEnd={updateActiveIndex}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                }}
                loop={true}
                pagination={{
                    clickable: true,
                    renderBullet: function (index, className) {
                        return `
                                <div class="${className} pagination-dot"></div>
                        `;
                    },

                }}
                navigation={{
                    nextEl: '.custom-swiper-button-next',
                    prevEl: '.custom-swiper-button-prev',
                }}

                className='h-[87vh] w-full flex relative items-center'
            >
                {images.map((data, index) => {
                    const isActive = activeIndex === index;
                    return <SwiperSlide key={index}>
                        <HeroSlide data={data} isGreaterThenmd={isGreaterThenmd} isActive={isActive} />

                    </SwiperSlide>
                })}
            </Swiper>
            <div className='hidden xl:flex custom-swiper-button-next absolute right-10 z-10 cursor-pointer rounded-full p-5 text-white hover:text-primary  bg-neutral-400 hover:bg-neutral-200 flex items-center justify-center ' >
                <BsChevronRight size={25} />
            </div>
            <div className='hidden xl:flex custom-swiper-button-prev absolute left-10 z-10 cursor-pointer rounded-full p-5 text-white hover:text-primary bg-neutral-400 hover:bg-neutral-200 flex items-center justify-center ' >
                <BsChevronLeft size={25} />
            </div>
        </div >
    )
}

const HeroSlide = ({ data, isGreaterThenmd, isActive }) => {
    const image = isGreaterThenmd ? data.image : data.mobileImageXs;

    const scrollWithOffset = (el, extra) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = (window.innerWidth > 768 ? -60 : -40) + extra;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return <div className='flex justify-center items-center w-full h-full'>
        <div className='absolute top-0 left-0 w-full h-full bg-black opacity-50 '></div>
        <img src={image} alt='heroimgs' className={`w-full h-full  object-cover ${data.objectPosition}`} />
        <div className='absolute w-[90%] md:w-[85%] xl:w-[1200px] top-1/2 transform -translate-y-1/2 xl:pl-[6%] 2xl:pl-0'>
            <div className={`flex flex-col gap-y-5 justify-center opacity-95 text-left drop-shadow-lg  text-white max-w-[90%] ${data.left ? "mr-auto lg:max-w-[60%]" : "lg:ml-auto lg:max-w-[50%]"} `}>
                <Fade top delay={200} when={isActive}>
                    <h1 className='relative text-5xl xl:text-6xl font-extrabold  open-sans'>{data.title}</h1>
                </Fade>
                <Fade bottom delay={600} when={isActive}>
                    <h2 className='text-3xl xl:text-4xl font-semibold  sm:max-w-[80%]' style={{ lineHeight: "1.2" }}>{data.subtitle}</h2>
                </Fade>
                <Fade bottom delay={1000} when={isActive}>
                    <div className='flex flex-col sm:flex-row  gap-x-5 md:gap-x-8 items-start'>
                        <Link to="#contact" scroll={el => scrollWithOffset(el, -50)}>
                            <div className='mt-4 bg-primary hover:bg-dark flex text-white px-7 md:px-10 py-4  rounded-full gap-x-4 items-start max-w-fit cursor-pointer'>
                                <div><FiPhoneCall size={23} /></div>
                                <p className='font-semibold text-md '>Contact us</p>
                            </div>
                        </Link>
                        <Link to="#about" scroll={el => scrollWithOffset(el, 0)}>
                            <p className='text-sm border-b-2 border-white text-white  pb-1 cursor-pointer mt-7 ml-5 sm:ml-0'>KNOW ABOUT US</p>
                        </Link>
                    </div>
                </Fade>
            </div>
        </div>
    </div>
}

export default Herosection