import React from 'react'
import products from './productsData'

const Products = () => {

    return (
        <div id="products" className='bg-light'>

            <div className='w-[90%] md:w-[85%] xl:w-[1200px] h-auto mx-auto py-20'>
                <div className='our-product-header-container flex flex-col lg:flex-row border-b  border-neutral-300 pb-2 gap-y-5 lg:gap-y-0 lg:gap-x-10'>
                    {/* our-products-header-container */}
                    <div className='flex-1 text-4xl font-medium lg:border-r lg:border-neutral-300'>
                        <h3>Our Products <span className='text-orange-primary'>Range</span></h3></div>
                    <div className='flex-[2] text-[18px] text-neutral-500'>
                        <p>Explore our diverse product portfolio, offering high-quality animal feeds, supplements, antibiotics, and more to cater to all your animal healthcare needs.</p>
                    </div>
                </div>
                <div className='mt-20 flex gap-x-10 gap-y-14 flex-wrap justify-center w-full'>
                    {
                        products.map((product, index) => {
                            return <ProductCard key={index} data={product} />
                        })
                    }
                </div>
            </div>
        </div>

    )
}

const ProductCard = ({ data }) => {
    // return <div className='product-card w-[270px] md:h-[370px] sm:w-[220px] sm:h-[300px] md:w-[270px] md:h-[370px] border-2 bg-white hover:shadow-[0px_48px_100px_0px_rgba(17,12,46,0.15)] duration-300'>
    return <div className='product-cardw-[270px] h-[370px] border-2 bg-white hover:shadow-[0px_48px_100px_0px_rgba(17,12,46,0.15)] duration-300'>
        <div className='w-full h-[70%]'>
            <img src={data.image} alt={data.name} className='w-full h-full' />
        </div>
        <div className='w-full h-[30%] flex justify-center items-center text-center px-5 border-t border-neutral-150'>
            <p className='text-[1.15rem] line-clamp-3 capitalize'>
                {data.name}
            </p>
        </div>


    </div>
}

export default Products