import React from 'react'
import BcuminLogo from "../assets/green and white.png"
import { AiFillFacebook, AiFillInstagram, AiFillTwitterSquare } from 'react-icons/ai'
import { HashLink as Link } from 'react-router-hash-link'

const Footer = () => {

    const scrollWithOffset = (el, extra) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -60 + extra;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const homeButtonCloseMenuAndRedirect = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }


    return (
        <div className='bg-background-dark'>

            <div className='w-[90%] md:w-[85%] xl:w-[1200px] h-auto mx-auto py-20 text-white flex flex-col md:flex-row justify-between gap-x-10 gap-y-12'>
                <div className='h-full flex-1'>
                    {/* column */}
                    <div className='w-[200px] h-full '>
                        <Link to="#" onClick={homeButtonCloseMenuAndRedirect}>

                            <img src={BcuminLogo} alt="bcumin logo" className='w-full h-full' />
                        </Link>

                    </div>
                    <p className='mt-7 opacity-75 text-sm text-justify'>
                        BCUMIN PHARMA is committed to improving the lives of animals through cutting-edge healthcare solutions. Join us in our journey to create a healthier and happier world for our beloved companions.
                    </p>
                    <p className='mt-5 md:mt-10 text-sm font-medium opacity-90'>Copyright ©2023 All Rights Reserved
                    </p>
                </div>
                <div className='flex-1 flex flex-col md:items-center'>
                    {/* coloumn */}
                    <div>

                        <div className='md:h-[55px] flex items-center'>
                            <h3 className='text-xl font-medium'>Quick Links</h3>
                        </div>
                        <div className='flex gap-x-10'>

                            <ul className='md:mt-7 text-sm opacity-75 '>
                                <Link to={"#"} onClick={homeButtonCloseMenuAndRedirect}>

                                    <li className='mt-2 hover:underline'>Home</li>
                                </Link>
                                <Link to={"#about"} scroll={el => scrollWithOffset(el, 0)} >
                                    <li className='mt-2 hover:underline'>About Us</li>
                                </Link>
                                <Link to={"#statements"} scroll={el => scrollWithOffset(el, 0)} >
                                    <li className='mt-2 hover:underline'>Statement</li>
                                </Link>

                                <Link to={"#services"} scroll={el => scrollWithOffset(el, 0)} >
                                    <li className='mt-2 hover:underline'>Services</li>
                                </Link>

                            </ul>
                            <ul className='md:mt-7 text-sm opacity-75 '>
                                <Link to={"#products"} scroll={el => scrollWithOffset(el, 0)} >
                                    <li className='mt-2 hover:underline'>Products</li>
                                </Link>
                                <Link to={"#contact"} scroll={el => scrollWithOffset(el, 0)} >
                                    <li className='mt-2 hover:underline'>Contact Us</li>
                                </Link>

                            </ul>
                        </div>
                    </div>


                </div>
                <div className='flex-1'>
                    {/* coloumn */}
                    <div className='md:h-[55px] flex items-center'>
                        <h3 className='text-xl font-medium'>Contact Information</h3>
                    </div>
                    <ul className='md:mt-7 text-sm opacity-75 '>
                        {/* <li className='mt-2'>Phone: +91 93091 00401</li> */}
                        <li className='mt-2 hover:underline'>
                            <a href="tel:+919309100401" className='mt-2 text-md opacity-90'>Phone: +91 93091 00401</a>
                        </li>

                        <li className='mt-2 hover:underline'>
                            <a href="mailto:bcuminpharma@gmail.com">Email: bcuminpharma@gmail.com</a>
                        </li>

                        <div className='flex gap-x-5 text-3xl mt-7 sm:mt-10'>
                            {/* all social media linked icons using react-icons*/}
                            <Link>
                                <div className='text-primary '>
                                    <AiFillFacebook />
                                </div>
                            </Link>
                            <Link>
                                <div className='text-primary '>
                                    <AiFillInstagram />
                                </div>
                            </Link>
                            <Link>
                                <div className='text-primary '>
                                    <AiFillTwitterSquare />
                                </div>
                            </Link>

                        </div>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default Footer