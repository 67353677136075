import React, { useState, useEffect } from 'react'
import Navbar from "./components/Navbar"
import Herosection from './components/Herosection'
import AboutUs from './components/AboutUs/AboutUs'
import Statements from './components//AboutUs/Statements'
import Services from './components/Services'
import Products from './components/Products/Products'
import Contact from './components/Contact'
import Footer from './components/Footer'

import ClipLoader from "react-spinners/ClipLoader";
import Career from './components/Career'


const App = () => {

  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <>
      {loading ?
        <div className="flex w-full h-[100vh] justify-center items-center">
          <ClipLoader
            className
            color={"#25723e"}
            loading={loading}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
        : <div className=''>
          <Navbar />
          <Herosection />
          <AboutUs />
          <Statements />
          <Services />
          <Products />
          <Career/>
          <Contact />
          <Footer />
        </div>}
    </>
  )
}

export default App