import Meglurex from "../../assets/og-products/Meglurex.jpg";
import BovirestPlus from "../../assets/og-products/Bovirest-plus-30ml.jpg";
import BavipasPlus from "../../assets/og-products/bavipas-plus.jpg";
import Enrorex20 from "../../assets/og-products/enrorex-20.jpg";
import ZybicC from "../../assets/og-products/zybic-c.jpg";
import MotorexCV3600 from "../../assets/og-products/motorex-cv 3600.jpg";
import Ketogly from "../../assets/og-products/ketogly.jpg";
import KiwitoneH from "../../assets/og-products/kiwitone-h-1L-edited.jpeg";
import Mestarch from "../../assets/og-products/mestarch.jpg";
import KiwitoneHInjection from "../../assets/og-products/Kiwitone-h-injection.jpg";
import cuvicalChleated from "../../assets/og-products/cuvical-chleated-big-edited.jpeg";

const products = [{
    name: "Ketogly",
    image: Ketogly,
}, {
    name: "Mestarch",
    image: Mestarch,
}, {
    name: "Kiwitone-H Injection",
    image: KiwitoneHInjection,
}, {
    name: "Meglurex",
    image: Meglurex,
}, {
    name: "Bovirest Plus",
    image: BovirestPlus,
}, {
    name: "Cuvical Chleated",
    image: cuvicalChleated,
},
{
    name: "Bavipas plus",
    image: BavipasPlus,
}, {
    name: "Enrorex 20",
    image: Enrorex20,
}, {
    name: "Zybic C",
    image: ZybicC,
},
{
    name: "Motorex cv 3600",
    image: MotorexCV3600,
},
{
    name: "Kiwitone H",
    image: KiwitoneH,
},
];

export default products;