import React from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs';
import { TfiEmail } from 'react-icons/tfi';

const Contact = () => {

    return (
        <div id="contact" className='bg-[#297241] relative w-full'>

            <div className='w-[90%] md:w-[85%] xl:w-[1200px] h-auto mx-auto py-14 lg:py-20 lg:min-h-[400px]'>
                <div className='text-white' >
                    {/* Text content */}
                    <div className='contact-header-container flex flex-col lg:flex-row gap-y-5 lg:gap-y-0 lg:gap-x-10'>
                        <div className='flex-1 text-4xl font-medium '>
                            <h3>Contact Details</h3></div>
                    </div>
                    <p className='mt-14 mb-5 text-[16px] font-medium'>VISIT US ON</p>
                    <div className='text-[18px] leading-[1.7]'>
                        <p>E-E 19, Jiwaji Lokhandwala Bldg,</p>
                        <p>Bapty road,</p>
                        <p>Mumbai - 400008</p>
                        {/* <p>DE 198 Tech Road</p>
                        <p>Bridge Str, Newyork 10026</p>
                        <p>United States.</p> */}
                    </div>
                </div>
            </div>

            <div className='w-[100%] lg:w-[60%] min-h-[400px] h-[100%] lg:absolute right-0 top-0'>
                {/* phone and email */}
                <div className='absolute bottom-2 left-2 z-[4] flex flex-col md:flex-row gap-x-5 gap-y-5'>
                    <div className='  bg-background-dark p-5 w-[100%] sm:w-[300px] rounded-xl text-white '>
                        <div className='flex gap-x-3 items-center '>
                            <p className='text-primary'><BsFillTelephoneFill /></p>
                            <p>PHONE</p>
                        </div>
                        <a href="tel:+919309100401" className='mt-2 text-md opacity-90'>+91 93091 00401</a>

                        {/* <p className='mt-2 text-md opacity-90'></p> */}
                    </div>
                    <div className=' bg-background-dark p-5 w-[100%] sm:w-[300px] rounded-xl text-white '>
                        <div className='flex gap-x-3 items-center '>
                            <p className='text-primary'><TfiEmail /></p>
                            <p>Email</p>
                        </div>
                        <a href="mailto:bcuminpharma@gmail.com" className='mt-2 text-md opacity-90'>bcuminpharma@gmail.com</a>

                    </div>
                </div>

                {/* map */}
                <div className='bg-black w-[100%] h-[100%] z-[2]'>
                    <iframe className='z-[2] bg-black opacity-80 focus:opacity-100 hover:opacity-100 z-[1]' title="bcumin office address"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d943.3166635113486!2d72.82305206954891!3d18.96382746720381!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce14a70ec649%3A0xdcddf8018bc2dca8!2sBapty%20Rd%2C%20Navjeevan%20Society%2C%20Mumbai%2C%20Maharashtra%20400008!5e0!3m2!1sen!2sin!4v1695541189051!5m2!1sen!2sin"
                        width="100%"
                        height="100%"

                        style={{ border: 0, minHeight: '400px' }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">


                    </iframe>
                </div>
            </div>
        </div>
    )
}

export default Contact