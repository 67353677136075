import React from 'react'
import careerSectionImage from "../assets/career/career-section.svg";
import { HashLink as Link } from 'react-router-hash-link'

const Career = () => {
    const scrollWithOffset = (el, extra) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = (window.innerWidth > 768 ? -60 : -40) + extra;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <div className='w-[90%] md:w-[85%] xl:w-[1200px] h-auto mx-auto py-20'>
            <div className='our-product-header-container flex flex-col lg:flex-row border-b  border-neutral-300 pb-2 gap-y-5 lg:gap-y-0 lg:gap-x-10'>
                {/* our-products-header-container */}
                <div className='flex-1 text-4xl font-medium lg:border-r lg:border-neutral-300'>
                    <h3>Join Our <span className='text-orange-primary'>Team</span></h3></div>

                <div className='flex-[2] text-[18px] text-neutral-500'>
                    <p >We are ever expanding company looking for bright young energetic persons with or without
                        experience but flair of marketing.</p>
                </div>

            </div>
            <div className='flex flex-col-reverse lg:flex-row items-center'>
                <div className='lg:flex-1 pt-14'>
                    <p className='text-neutral-500  text-justify'>At BCUMIN PHARMA, we believe in assembling a team of passionate individuals committed to shaping the future of animal healthcare. We're dedicated to fostering a work environment that encourages creativity, innovation, and collaboration.
                    </p>
                    <div className='flex mt-5 mb-8 gap-5 gap-x-10  flex-wrap'>
                        <div className='bg-light p-3 rounded-md w-full'>
                            <h3 className='opacity-80 text-sm'><span className='text-orange-primary font-medium text-base'> Collaboration:</span> Highlighting the importance of teamwork and cooperation within the workplace.</h3>
                        </div>
                        <div className='bg-light p-3 rounded-md w-full'>
                            <h3 className='opacity-80 text-sm'><span className='text-orange-primary font-medium text-base'> Growth:</span> Emphasizing opportunities for career development and personal advancement.</h3>
                        </div>

                    </div>

                    <Link to="#contact" scroll={el => scrollWithOffset(el, -50)}>
                        <div className='mt-4 bg-primary hover:bg-dark flex text-white px-7 md:px-10 py-4  rounded-full gap-x-4 items-start max-w-fit cursor-pointer'>
                            {/* <div><FiPhoneCall size={23} /></div> */}
                            <p className='font-semibold text-md '>Join us</p>
                        </div>
                    </Link>
                </div>
                <div className='lg:flex-1 w-full md:w-[70%] lg:w-full'>
                    <img src={careerSectionImage} alt="" className='w-full' />
                </div>
            </div>
        </div>

    )
}

export default Career