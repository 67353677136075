import React from 'react'
import cowHeroImage from "../../assets/about/photo-1.jpg"
import henHeroImage from "../../assets/about/photo-2.jpg"

const AboutUs = () => {

    return (
        <div id="about" className='w-[90%] md:w-[85%] xl:w-[1200px] mx-auto pt-20 md:pt-28'>
            <div className='flex flex-col gap-y-10 lg:gap-y-0 lg:flex-row justify-between'>
                <div className='flex flex-col gap-y-4 sm:gap-y-6 lg:max-w-[50%]'>
                    {/* content */}
                    <h1 className='text-5xl sm:text-6xl font-medium'>Welcome to <br /><span className='text-orange-primary'>BCUMIN PHARMA</span> !</h1>
                    <h3 className='text-xl sm:text-2xl text-primary-text leading-[1.5]'>Explore a brighter, healthier future in veterinary medicine.</h3>
                    <p className='text-md text-neutral-500 text-justify '>
                        Bcumin Pharma is a young startup with a driving force on bringing innovative and research base
                        products to benefit the farmers and veterinary service providers.

                    </p>
                    <p className='text-md text-neutral-500  text-justify'>
                        Bcumin Pharma has developed functional partnerships with leading companies dedicated to
                        bring modern veterinary drug delivery system to the farmer doorstep.</p>
                </div>
                <div className='flex relative md:right-8 lg:right-0 max-w-[100%]'>
                    {/* image */}
                    <div className='mx-auto w-[90%] sm:w-[300px] sm:h-[400px] relative md:left-16'>
                        <img src={cowHeroImage} alt="cow" className='w-full h-full' />
                    </div>
                    <div className='hidden md:block w-[300px] h-[400px] mt-16'>
                        <img src={henHeroImage} alt="cow" className='w-full h-full' />
                    </div>
                </div>
            </div>
        </div>
    )
}



export default AboutUs