import React, { useState } from 'react'
import missionIcon from "../../assets/about/mission.png"
import visionIcon from "../../assets/about/vision.png"
import valueIcon from "../../assets/about/value.png"

const Statements = () => {
    const [missionFlex, setMissionFlex] = useState('md:flex-1')
    const [visionFlex, setVisionFlex] = useState('md:flex-1')
    const [valueFlex, setValueFlex] = useState('md:flex-1')
    const [flippedState, setFlippedState] = useState([false, false, false])

    const statementData = [
        {
            icon: missionIcon,
            title: 'Our Mission',
            content: 'Enhancing animal productivity through innovative and affordable healthcare solution.',
            flex: missionFlex,
            isFlipped: flippedState[0]


        },
        {
            icon: visionIcon,
            title: 'Our Vision',
            content: 'By next decade Bcumin Pharma would be a major player in novel veterinary drug delivery system employing micro encapsulation, nano drug and barrier film technologies.',
            flex: visionFlex,
            isFlipped: flippedState[1]
        },
        {
            icon: valueIcon,
            title: 'Our Values',
            content: 'Green technology, unique products, healthy animals, and safe food.',
            flex: valueFlex,
            isFlipped: flippedState[2]
        },
    ]

    const updateFlex = (index) => {
        if (index === 0) {
            setMissionFlex('md:flex-[2]')
            setVisionFlex('md:flex-1')
            setValueFlex('md:flex-1')
        } else if (index === 1) {
            setMissionFlex('md:flex-1')
            setVisionFlex('md:flex-[2]')
            setValueFlex('md:flex-1')
        } else if (index === 2) {
            setMissionFlex('md:flex-1')
            setVisionFlex('md:flex-1')
            setValueFlex('md:flex-[2]')
        }
    }
    const resetFlex = () => {
        setMissionFlex('md:flex-1')
        setVisionFlex('md:flex-1')
        setValueFlex('md:flex-1')
    }

    const resetAllFlips = () => {
        setFlippedState([false, false, false]);
        resetFlex();
    }

    const handleFlip = (index) => {
        let temp = [...flippedState];
        temp[index] = !temp[index];
        setFlippedState(temp);
    }
    return (

        <div id="statements" className='w-[90%] md:w-[85%] xl:w-[1200px] h-auto mx-auto flex flex-col md:flex-row gap-y-6 md:gap-y-0 md:gap-x-10 pt-20'>
            {/* Misson vissio value */}
            {statementData.map((data, index) => {
                return <StatementCard key={index} index={index} data={data} updateFlex={updateFlex} resetFlex={resetFlex} resetAllFlips={resetAllFlips} handleFlip={handleFlip} flippedState={flippedState} />
            })}
        </div>
    )
}
export const StatementCard = ({ data, index, updateFlex, resetAllFlips, handleFlip, flippedState }) => {

    const handleHover = () => {
        resetAllFlips();
        updateFlex(index);
        setTimeout(() => {
            handleFlip(index);
        }, 700);

    }


    const handleLeave = () => {
        resetAllFlips();
    }


    return (
        <div className={`duration-500 flex ${data.flex} h-[200px] gap-x-4 w-auto `} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
            <div className={`statement-card  ${(data.flex === "md:flex-[2]" && flippedState[index]) ? 'flipped' : ''}`}>
                <div className='front' >
                    {/* Front side of the card */}
                    <div onClick={handleHover} className='bg-light cursor-pointer rounded-xl flex flex-col gap-y-5 justify-center items-center h-[200px]'>
                        <div className='w-[50px]'>
                            <img src={data.icon} alt={data.title} className='w-full h-full' />
                        </div>
                        <div>

                            <p className='text-xl text-primary-text'>{data.title}</p>
                            <p className='md:hidden mt-2 text-center text-sm underline'>know more</p>

                        </div>
                    </div>
                </div>
                <div onClick={handleLeave} className='back relative ' >
                    {/* Back side of the card */}
                    <div className='rounded-lg bg-primary text-white text-center flex justify-center items-center flex-col gap-y-5 h-[200px] px-5 md:px-10'>
                        <p className='text-xl border-b-2 border-white pb-2'>{data.title}</p>
                        <p className='text-md'>{data.content}</p>

                    </div>
                </div>
            </div>
        </div >
    );
};
export default Statements