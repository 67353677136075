import React, { useEffect, useState } from 'react'
import BcuminLogoColored from "../assets/bcumin-og-color-logo.png"
import { HashLink as Link } from 'react-router-hash-link'
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";


const Navbar = () => {
    const [isScrolling, setIsScrolling] = useState(false)
    const [isSticky, setIsSticky] = useState(false)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const [activeSection, setActiveSection] = useState("home");
    const [isOverlay, setIsOverlay] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleIsScolled)

        return () => {
            window.removeEventListener('scroll', handleIsScolled);
        }

    }, [])


    useEffect(() => {
        // Function to handle scroll and update the active section

        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Define the thresholds for each section
            const sections = {
                home: 0,
                about: document.getElementById("about").offsetTop - 100,
                services: document.getElementById("services").offsetTop - 100,
                products: document.getElementById("products").offsetTop - 100,
                contact: document.getElementById("contact").offsetTop - 100,
            };

            // Find the active section
            let newActiveSection = null;
            for (const section in sections) {
                if (scrollPosition >= sections[section]) {
                    newActiveSection = section;
                }
            }

            // Update the active section

            setActiveSection(newActiveSection);
        };

        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // check if overlay is open then add class stop-scrolling to body
        if (isOverlay) {
            document.body.classList.add("stop-scrolling");
        }
        else {
            document.body.classList.remove("stop-scrolling");
        }
    }, [isOverlay])

    const handleIsScolled = () => {
        const height = document.querySelector('.navbar-outer-container').clientHeight;
        // set css variable for navbar height
        document.documentElement.style.setProperty('--navbar-height', `${height}px`);

        // const nav = document.querySelector('.outer-container')
        if (window.scrollY > height) {
            setIsScrolling(true)
        } else {
            setIsScrolling(false)
        }
        if (window.scrollY > height + 10) {
            setIsSticky(true)
        }
        if (window.scrollY === 0) {
            setIsSticky(false)
        }
    }

    const toggleMobileMenu = () => {

        if (isMobileMenuOpen) {
            // closing
            setIsOverlay(false);
        } else {
            // opening
            setIsOverlay(true);
        }
        setIsMobileMenuOpen(!isMobileMenuOpen);
    }


    const scrollWithOffset = (el, extra) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -60 + extra;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        if (isMobileMenuOpen) {
            setIsOverlay(false);
            setIsMobileMenuOpen(false);
        }
    }

    const homeButtonCloseMenuAndRedirect = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (isMobileMenuOpen) {
            setIsOverlay(false);
            setIsMobileMenuOpen(false);
        }
    }

    return (
        <>
            <div className={` navbar-outer-container bg-light ${isScrolling && `is-scrolling`} ${isSticky && `sticky-navbar`} z-[99]`}>
                <div className='inner-container flex max-w-[90%] md:max-w-[85%] xl:max-w-[1200px]  mx-auto justify-between items-center py-[20px]'>
                    <div className='logo-container h-auto w-[150px] md:w-[190px] py-2'>
                        <Link to='/#' className={`hover:text-primary ${activeSection === "home" ? "text-primary" : ""
                            }`} onClick={homeButtonCloseMenuAndRedirect}>
                            <img src={BcuminLogoColored} alt='bcumin logo' className='logo w-full h-full' />
                        </Link>
                    </div>
                    <div className='navlinks hidden md:flex gap-x-10 text-[16px] font-medium'>
                        <Link to='#' className={`hover:text-primary ${activeSection === "home" ? "text-primary" : ""
                            }`} onClick={homeButtonCloseMenuAndRedirect}>
                            Home</Link>
                        <Link to='#about' scroll={el => scrollWithOffset(el, 0)} className={`hover:text-primary ${activeSection === "about" ? "text-primary" : ""
                            }`}>About Us</Link>
                        <Link to='#services' scroll={el => scrollWithOffset(el, 0)} className={`hover:text-primary ${activeSection === "services" ? "text-primary" : ""
                            }`}>Services</Link>
                        <Link to='#products' scroll={el => scrollWithOffset(el, 0)} className={`hover:text-primary ${activeSection === "products" ? "text-primary" : ""
                            }`}>Products</Link>
                        <Link to='#contact' scroll={el => scrollWithOffset(el, -50)} className={`hover:text-primary ${activeSection === "contact" ? "text-primary" : ""
                            }`}>Contact</Link>
                    </div>
                    {/* responsive navlinks */}
                    <div className={`md:hidden opacity-1 ${isMobileMenuOpen && "opacity-0"}`}>
                        <HiOutlineMenuAlt3 size={32} onClick={toggleMobileMenu} />

                    </div>
                </div>
            </div>

            <div className={`responsive-navlinks block md:hidden w-[70vw] sm:w-[50vw] h-full fixed top-0 left-0 translate-x-[-100vw] bg-white z-[100]  ${isMobileMenuOpen && "translate-x-[0px]"}`}>
                <div className='absolute top-5 right-5 z-[100]'>
                    <AiOutlineCloseCircle size={28} onClick={toggleMobileMenu} />
                </div>


                <div className='navlinks flex flex-col items-left text-left mt-14 gap-y-5 gap-x-10 text-[16px] font-medium px-10'>
                    <div className='logo-container h-auto w-[180px] mb-5'>
                        <div onClick={homeButtonCloseMenuAndRedirect} className={`${activeSection === "home" ? "text-primary" : ""
                            }`} >
                            <img src={BcuminLogoColored} alt='bcumin logo' className='logo w-full h-full' />
                        </div>
                    </div>
                    <div to='#' className={`hover:text-primary ${activeSection === "home" ? "text-primary" : ""
                        }`} onClick={homeButtonCloseMenuAndRedirect}>
                        Home</div>
                    <Link to='#about' scroll={el => scrollWithOffset(el, 40)} className={`hover:text-primary ${activeSection === "about" ? "text-primary" : ""
                        }`}>About Us</Link>
                    <Link to='#services' scroll={el => scrollWithOffset(el, 40)} className={`hover:text-primary ${activeSection === "services" ? "text-primary" : ""
                        }`}>Services</Link>
                    <Link to='#products' scroll={el => scrollWithOffset(el, 40)} className={`hover:text-primary ${activeSection === "products" ? "text-primary" : ""
                        }`}>Products</Link>
                    <Link to='#contact' scroll={el => scrollWithOffset(el, 0)} className={`hover:text-primary ${activeSection === "contact" ? "text-primary" : ""
                        }`}>Contact</Link>
                </div>
            </div>
            <div id="overlay" style={{ display: isOverlay ? "block" : "none" }} onClick={(e) => e.stopPropagation()}>

            </div >
        </>
    )
}

export default Navbar