import React, { useState } from 'react'
import serviceImage1 from "../assets/services/1.jpg";
import serviceImage2 from "../assets/services/2.jpg";
import serviceImage3 from "../assets/services/3.jpg";
import serviceImage4 from "../assets/services/4.jpg";
import NVGIcon from "../assets/services/NVG sup.png";
import foodAndSuplementsIcon from "../assets/services/Feeds & Supplements.png";
import antibioticsIcon from "../assets/services/Antibiotic.png";
import otherServicesIcon from "../assets/services/other.png";
import pillsIcon from "../assets/pills.png";

const Services = () => {
  const mainServices = [{
    name: "Novel Microencapsulated Glucose Supplements",
    description: "Patented microencapsulated process to deliver glycogenic nutrients directly to intestine.",
    image: serviceImage1,
    icon: NVGIcon,
    showPdfs: true,
  }, {
    name: "Antibiotics",
    description: "Our anti-infective drugs support veterinary service providers to rapidly treat and control livestock diseases.",
    image: serviceImage2,
    icon: antibioticsIcon
  }, {
    name: "Animal feed supplements",
    description: "We provide premium animal feed supplements crafted with utmost quality.",
    image: serviceImage3,
    icon: foodAndSuplementsIcon
  }, {
    name: "Other Services",
    description: `Explore our comprehensive product selection in the "Products" section, offering a diverse range of animal healthcare solutions.`,
    image: serviceImage4,
    icon: otherServicesIcon
  }
  ]
  return (
    <div id="services" className='w-[90%] md:w-[85%] xl:w-[1200px] h-auto mx-auto py-20 md:py-28'>
      <div className=''>
        {/* our-service-header-outer-container */}
        <div className='our-service-header-container flex flex-col lg:flex-row border-b relative  border-neutral-100 pb-2 gap-y-5 lg:gap-y-0 lg:gap-x-10'>
          {/* our-service-header-container */}
          <img src={pillsIcon} alt="capsule" className='w-[110px] h-[110px] absolute left-[0px] top-[-50px]' />
          <div className='flex-1 text-4xl font-medium lg:border-r lg:border-neutral-100 z-[2]'><h3>Our Main <span className='text-orange-primary'>Services</span></h3></div>
          <div className='flex-[2] text-[18px] text-neutral-500'>
            <p>We care for not only animals but also farmers and veterinary service providers. We believe in
              linking farmer’s problems to veterinary experts creating mutually beneficial ecosystems.</p>
          </div>
        </div>

      </div>
      <div className='mt-16 flex justify-around flex-wrap gap-7'>
        {/* ServiceCards container */}
        {mainServices.map((service, index) => {
          return <ServiceCard key={index} data={service} />
        })
        }

      </div>
    </div>
  )
}

const ServiceCard = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = () => {
    setIsHovered(!isHovered)
  }


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openMestarchPdf = () => {
    // Replace 'path_to_your_pdf.pdf' with the actual path to your PDF file
    // window.open(process.env.PUBLIC_URL + '../assets/Mestarch.pdf', '_blank');
    window.open("https://drive.google.com/file/d/1iHxiNBSAxD1WE51uvXyBr3sMv0IV_qB_/view?usp=sharing", '_blank');
  };
  const openKetologyPdf = () => {
    window.open('https://drive.google.com/file/d/13Tv5uk6GhvEbubLheXb9i0tgiAVp3xCL/view?usp=sharing', '_blank');

  }

  return <div
    className={`service-card w-[270px] h-[390px] border rounded-[1.6rem]  ${isHovered ? 'group' : ''
      }`}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    onClick={handleClick}

  >
    {/* outer container */}
    <div className='w-full h-full relative overflow-hidden'>
      {/* inner container */}
      <div className='w-full h-full'>
        {/* image  */}
        <img src={data.image} alt={data.name} className='w-full h-full rounded-3xl  object-cover' />
      </div>
      <div className='absolute bottom-0 w-full text-center   overflow-hidden' >
        {/* logo and name container */}
        <div className='triangle-icon-container flex justify-center items-center z-[1] group-hover:translate-y-[200px] duration-700 sticky z-[1]' >
          {/* icon triangle */}
          <div className='w-0 h-0
        border-l-[130px] border-l-transparent
        border-b-[110px] border-b-white
        border-r-[140px] border-r-transparent '>
          </div>
          <img src={data.icon} alt={data.name} className={`w-[55px] h-[55px] object-contain absolute top-[35%] ${data.left && `left-[38%]`}`} />
        </div>

        <div className='w-full  h-16 bg-white group-hover:bg-light rounded-b-3xl flex justify-center  text-primary capitalize z-[10] sticky'>
          {/* bottom name slate */}
          <p className='text-[16px] group-hover:translate-y-[17px] duration-500'>{data.name}</p>
        </div>
      </div>
      {/* <div className='opacity-0 h-[50%] group-hover:h-[100%] group-hover:opacity-100 bg-[#2E7445] absolute bottom-0 h-full w-full z-[2] rounded-3xl translate-y-[10%] group-hover:translate-y-[0px] duration-700'> */}
      <div className=' h-[50%] group-hover:h-[100%] opacity-0 group-hover:opacity-100 bg-[#2E7445] absolute bottom-0 h-full w-full z-[2] rounded-3xl duration-700 text-center text-white px-7'>
        {/* floatable text slate */}
        <p className='mt-16 mx-auto duration-700 text-md opacity-0 delay-700 group-hover:opacity-100 duration-500'>{data.description}
          {data.showPdfs && <div className='mt-6 flex gap-x-5 mx-auto w-fit'>
            <p onClick={isHovered && openMestarchPdf} className='text-sm text-primary bg-white rounded-xl px-3 py-2 cursor-pointer'>Mestarch</p>
            <p onClick={isHovered && openKetologyPdf} className='text-sm text-primary bg-white rounded-xl px-3 py-2 cursor-pointer'>Ketology</p>

          </div>}</p>

      </div>
    </div>
  </div >
}

export default Services